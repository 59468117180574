<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-3.mp3"),
      },
      timeStampList: [4.5, 9.5, 14.2, 18.9, 25.5],

      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Yī, yī, yī èr yī.",
            chineseWords: "一、一,一二一,",
          },
          {
            id: 2,
            spelling: "Yī, èr, sān, sì, wǔ, yī èr yī.",
            chineseWords: "一、二、三、四、五，一二一。",
          },
          {
            id: 3,
            spelling: "Lǎoshī! Lǎoshī! Lǎoshī hǎo!",
            chineseWords: "老师！老师！老师好！",
          },
          {
            id: 4,
            spelling: "Nǐ hǎo! Nǐ hǎo! Lǎoshī hǎo!",
            chineseWords: "你好！你好！老师好！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
